import styles from './Comments.module.css';
import Comment from './Comment.jsx';

const Comments = (props) => {
	const sortComments = (a, b) => {
		if(a.created_at > b.created_at){
			return -1;
		}
		else if(a.created_at < b.created_at){
			return 1;
		}
		return 0;
	};

	return (
		<div className={props.className} style={props.style}>
			<h2 className={styles.header}>
				Comments ({props.comments.length})
			</h2>
			<div className={styles.description}>
				Please donate to share comments
			</div>
			<div className={styles.comments}>
				{
					props.comments.sort(sortComments).map(comment => (
						<Comment className={styles.comment} comment={comment} key={'comment-' + comment.id}/>
					))
				}
			</div>
		</div>
	)
};

export default Comments;