import classNames from 'classnames';
import styles from './BottomViewHeaders.module.css';

const BottomViewHeaders = (props) => {
    return (
        <div className={classNames(styles.container, props.className)}>
            <span className={classNames(styles.header, {[styles.selected]: props.selectedView === 'comments'})} onClick={() => props.setView('comments')}>
                Comments ({props.numOfComments})
            </span>
            <span className={classNames(styles.header, {[styles.selected]: props.selectedView === 'updates'})} onClick={() => props.setView('updates')}>
                Updates ({props.numOfUpdates})
            </span>
        </div>
    )
};

export default BottomViewHeaders;