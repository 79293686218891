import { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import styles from './FundraiserListing.module.css';
import getFundraiserStatus from "../utils/getFundraiserStatus";
import formatNumber from "../utils/formatNumber";

const FundraiserListing = (props) => {
    const [status, setStatus] = useState('');
    const [statusClassName, setStatusClassName] = useState('');
    const [progressPercent, setProgressPercent] = useState(0);

    const getStatus = () => {
        const statusObject = getFundraiserStatus(props.fundraiser.start_at, props.fundraiser.end_at)
        setStatus(statusObject.status);
        setStatusClassName(statusObject.statusClassName);
    };

    const getProgressPercent = () => {
        if(!props.fundraiser.total_donations_amount){
            return;
        }
        setProgressPercent(Math.min((props.fundraiser.total_donations_amount / props.fundraiser.goal_amount) * 100, 100));
    };

    useEffect(() => {
        getStatus();
        getProgressPercent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <a className={classNames(styles.container, props.className)} href={'/fundraiser/' + props.fundraiser.id} target="_blank" style={props.style}>
            <h3 className={styles.name}>
                {props.fundraiser.name}
            </h3>
            <div className={classNames(styles.status, styles[statusClassName])}>
                {status}
            </div>
            {
                !props.hideOrganization && (
                    <div className={styles.organization}>
                        <span className={styles.organizationLabel}>
                            Organization:
                        </span>
                                <span className={styles.organizationName}>
                            {props.fundraiser.organization_name ? props.fundraiser.organization_name : 'Giverist Foundation'}
                        </span>
                        {
                            props.fundraiser.organization_picture_url && (
                                <img className={styles.organizationPicture} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + props.fundraiser.organization_picture_url}
                                     alt="Organization"/>
                            )
                        }
                    </div>
                )
            }
            <div className={styles.description}>
                {props.fundraiser.description}
            </div>
            <div className={styles.progress}>
				<span className={styles.label}>
					Progress Towards Goal
				</span>
                <div className={styles.progressBar}>
                    <div className={styles.progressMade} style={{width: progressPercent + '%'}}/>
                </div>
            </div>
            <div className={styles.firstInfoRow}>
                <div className={styles.endAt}>
					<span className={styles.label}>
						Deadline
					</span>
                    <span className={styles.value}>
						{moment(props.fundraiser.end_at).format('MMM Do, YYYY')}
					</span>
                </div>
                <div className={styles.startAt}>
					<span className={styles.label}>
						Start Date
					</span>
                    <span className={styles.value}>
						{moment(props.fundraiser.start_at).format('MMM Do, YYYY')}
					</span>
                </div>
            </div>
            <div className={styles.secondInfoRow}>
                <div className={styles.totalDonations}>
					<span className={styles.label}>
						Amount Donated
					</span>
                    <span className={styles.value}>
						{props.fundraiser.total_donations_amount ? '$' + formatNumber(props.fundraiser.total_donations_amount) : 'No Donations'}
					</span>
                </div>
            </div>
        </a>
    )
};

export default FundraiserListing;