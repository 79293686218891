import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import bubbleCheckmark from '../assets/images/bubble-checkmark.png';
import styles from './Receipts.module.css';
import { getReceiptsApi } from "../api/receipts";
import LoggedInNav from '../components/logged-in/LoggedInNav.jsx';
import LoggedOutNav from '../components/logged-out/LoggedOutNav.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import FormInput from '../components/form/FormInput.jsx';
import FormLastFour from '../components/form/FormLastFour.jsx';
import FormButton from '../components/form/FormButton.jsx';
import FormError from '../components/form/FormError.jsx';

const Receipts = () => {
	const { user } = useOutletContext();
	const [formData, setFormData] = useState({
		email: '',
		lastFour: ''
	});
	const initialErrorObject = {
		email: false,
		lastFour: false,
		apiError: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		email: 'Required',
		lastFour: 'Required'
	};
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [receiptsRequested, setReceiptsRequested] = useState(false);

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if((key === 'email' && !formData[key]) || (key === 'lastFour' && formData[key].length < 4)){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setRequestInProgress(true);
		if(checkForEmptyInputs()){
			setRequestInProgress(false);
			return;
		}
		const response = await getReceiptsApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			setReceiptsRequested(true)
		}
		else{
			const error = body?.error ? body.error : 'Request failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setRequestInProgress(false);
	};

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	return (
		<div>
			{
				user.id ? (
					<LoggedInNav/>
				) : (
					<LoggedOutNav/>
				)
			}
			<div className={styles.container}>
				{
					!receiptsRequested ? (
						<form onSubmit={submit}>
							<FormHeader className={styles.header} text="Request donation receipts"/>
							<FormInput className={styles.email} label="Email" type="email" placeholder="ie. johnsmith@gmail.com" value={formData.email}
									   update={(event) => setFormData({...formData, email: event.target.value})} error={errorObject.email} required/>
							<FormLastFour className={styles.lastFour} label="Last 4 of credit card" value={formData.lastFour}
										  update={(value) => setFormData({...formData, lastFour: value})} error={errorObject.lastFour} required/>
							<div className={styles.bottomSection}>
								{
									errorObject.apiError && (
										<FormError className={styles.apiError} error={errorObject.apiError}/>
									)
								}
								<FormButton className={styles.button} text="Request" disabled={requestInProgress}/>
							</div>
						</form>
					) : (
						<div>
							<img className={styles.bubbleCheckmark} src={bubbleCheckmark} alt="Bubble Checkmark"/>
							<div className={styles.requestedText}>
								Receipts will be emailed to you if the info you provided is valid
							</div>
						</div>
					)
				}
			</div>
		</div>
	)
};

export default Receipts;