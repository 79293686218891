import { useEffect } from 'react';
import dollarCircleOrange from '../../assets/images/dollar-circle-orange.png';
import eyeCircleRed from '../../assets/images/eye-circle-red.png';
import styles from './Notifications.module.css';
import { updateAllNotificationsApi } from '../../api/notifications.js';
import parseDatetimeText from '../../utils/parseDatetimeText.js';

const Notifications = (props) => {
	const sortByDate = (a, b) => {
		if(a.created_at > b.created_at){
			return -1;
		}
		else if(a.created_at < b.created_at){
			return 1;
		}
		return 0;
	};

	useEffect(() => {
		updateAllNotificationsApi();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.notifications} style={props.style}>
			{
				props.notifications?.length > 0 && props.notifications.sort(sortByDate).map(notification => (
					<a className={styles.notification} href={notification.target_url} key={notification.id}>
						<img className={styles.dollarCircleOrange} src={dollarCircleOrange} alt="Dollar Sign"/>
						<div className={styles.middleSection}>
							<div>
								{notification.text}
							</div>
							<div className={styles.datetime}>
								{parseDatetimeText(notification.created_at)}
							</div>
						</div>
						{
							!notification.seen && (
								<img className={styles.eyeCircleRed} src={eyeCircleRed} alt="Eye Circle"/>
							)
						}
					</a>
				))
			}
			{
				props.notifications?.length === 0 && (
					<div className={styles.noNotifications}>
						No notifications
					</div>
				)
			}
		</div>
	);
};

export default Notifications;