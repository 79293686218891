import classNames from 'classnames';
import moment from 'moment';
import styles from './Update.module.css';

const Update = (props) => {
    const sortImages = (a, b) => {
        if(a.sort_order > b.sort_order){
            return 1;
        }
        else if(a.sort_order < b.sort_order){
            return -1;
        }
        return 0;
    };

	return (
        <div className={classNames(styles.container, props.className)} style={props.style}>
            <div className={styles.topRow}>
                <div className={styles.circleContainer}>
                    {
                        props.index === 0 && (
                            <div className={styles.borderCover}/>
                        )
                    }
                    <div className={styles.outerCircle}>
                        <div className={styles.innerCircle}/>
                    </div>
                </div>
                <div className={styles.title}>
                    {props.update.title}
                </div>
                <div className={styles.organizer}>
                    {props.update.profile_picture_url && (
                        <img className={styles.organizerPicture} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + props.update.profile_picture_url} alt="Organizer"/>
                    )}
                    <span className={styles.organizerName}>
                        {props.update.first_name} {props.update.last_name}
                    </span>
                </div>
            </div>
            <div className={styles.middleRow}>
                <div className={styles.datetime}>
                    {moment(props.update?.created_at).format('MMMM D, YYYY h:mma')}
                </div>
            </div>
            <div className={styles.text}>
                {props.update.text}
            </div>
            {
                props.update.pictures && (
                    <div className={styles.pictures}>
                        {
                            props.update.pictures.sort(sortImages).map(picture => (
                                <img className={styles.picture} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + picture.url} alt={picture.url}
                                     onClick={() => props.openPhotoModal(picture.url)} key={picture.url}/>
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
};

export default Update;