import classNames from 'classnames';
import styles from './FormRadioButton.module.css';

const FormRadioButton = (props) => {
    const classes = classNames(styles.container, props.className, {
        [styles.selected]: props.value === props.selectedValue
    });

	return (
        <div className={classes} onClick={props.onClick}>
            <span className={styles.outerCircle}>
                <span className={styles.innerCircle}/>
            </span>
            <span className={styles.text}>
                {props.text}
            </span>
        </div>
	)
};

export default FormRadioButton;