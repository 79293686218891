import { useState } from 'react';
import { useOutletContext } from "react-router-dom";
import classNames from 'classnames';
import moment from "moment/moment";
import close from '../../assets/images/close.png';
import styles from './AddUpdateModal.module.css';
import { createFundraiserUpdateApi } from "../../api/fundraisers";
import Button from '../Button.jsx';
import ImagesSection from "../ImagesSection";
import FormInput from "../form/FormInput";
import FormTextEditor from "../form/FormTextEditor";
import FormError from "../form/FormError";
import FormButton from "../form/FormButton";

const AddUpdateModal = (props) => {
    const { user } = useOutletContext();
    const [formData, setFormData] = useState({
        title: '',
        text: '',
        images: []
    });
    const initialErrorObject = {
        title: false,
        text: false
    };
    const [errorObject, setErrorObject] = useState(initialErrorObject);
    const inputsMessagesErrorObject = {
        title: 'Required',
        text: 'Required'
    };
    const [creationInProgress, setCreationInProgress] = useState(false);

    const checkForEmptyInputs = () => {
        setErrorObject(initialErrorObject);
        const newErrorObject = structuredClone(initialErrorObject);
        let foundError = false;
        for(const key of Object.keys(formData)){
            if(!formData[key]){
                newErrorObject[key] = inputsMessagesErrorObject[key];
                foundError = true;
            }
        }
        setErrorObject(newErrorObject);
        return foundError;
    };

    const submit = async(event) => {
        event.preventDefault();
        setErrorObject(initialErrorObject);
        setCreationInProgress(true);
        if(checkForEmptyInputs()){
            setCreationInProgress(false);
            return;
        }
        const createFundraiserUpdateResponse = await createFundraiserUpdateApi(props.fundraiserId, formData);
        let body;
        try{
            body = await createFundraiserUpdateResponse.json();
        }
        catch(e){}
        if(createFundraiserUpdateResponse && createFundraiserUpdateResponse.ok){
            props.addUpdate({
                id: body.data.fundraiser_update_id,
                title: formData.title,
                text: formData.text,
                pictures: formData.images,
                first_name: user.first_name,
                last_name: user.last_name,
                profile_picture_url: user.profile_picture_url,
                created_at: moment().toISOString()
            });
            props.close();
        }
        else{
            const error = body?.error ? body.error : 'Create fundraiser update failed';
            setErrorObject((prevState) => ({...prevState, apiError: error}));
        }
        setCreationInProgress(false);
    };

    return (
        <div className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.header}>
                    Post update
                </div>
                <img className={styles.close} src={close} alt="Close" onClick={props.close}/>
                <form className={styles.form} onSubmit={submit}>
                    <div className={styles.formInputs}>
                        <FormInput className={styles.input} label="Title" type="text" placeholder="Title" maxlength={60} value={formData.title}
                                   update={(event) => setFormData({...formData, title: event.target.value})} error={errorObject.title} required/>
                        <FormTextEditor className={styles.textEditor} label="Text" placeholder="1000 characters max" maxlength={1000} value={formData.text}
                                        update={(event) => setFormData({...formData, text: event.target.value})} error={errorObject.text} required/>
                        <ImagesSection formData={formData} setFormData={setFormData} user={user} type="fundraiser-update"/>
                    </div>
                    {
                        errorObject.apiError && (
                            <FormError error={errorObject.apiError}/>
                        )
                    }
                    <div className={classNames(styles.buttons, {[styles.buttonsApiError]: errorObject.apiError})}>
                        <Button className={styles.button} text="Cancel" onClick={props.close}/>
                        <FormButton className={styles.button} style={{marginLeft: 'auto'}} text="Post" disabled={creationInProgress}/>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default AddUpdateModal;