import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import Bugsnag from "@bugsnag/js";
import clock from '../assets/images/clock.png';
import refreshClock from '../assets/images/refresh-clock.png';
import styles from './CreateFundraiser.module.css';
import { createFundraiserApi } from '../api/fundraisers.js';
import { getOrganizationsApi } from "../api/organizations";
import findArrayIndex from '../utils/findArrayIndex.js';
import { startTimeOptions, startTimezoneOptions, lengthsOptions } from '../data/CreateFundraiserOptions.js';
import LoggedInNav from '../components/logged-in/LoggedInNav.jsx';
import ImagesSection from '../components/ImagesSection.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import FormInput from '../components/form/FormInput.jsx';
import FormCheckbox from '../components/form/FormCheckbox.jsx';
import FormDateInput from '../components/form/FormDateInput.jsx';
import FormDropdown from '../components/form/FormDropdown.jsx';
import FormMoneyInput from '../components/form/FormMoneyInput.jsx';
import FormTextEditor from '../components/form/FormTextEditor.jsx';
import FormButton from '../components/form/FormButton.jsx';
import FormError from '../components/form/FormError.jsx';

const CreateFundraiser = () => {
	const navigate = useNavigate();
	const { user } = useOutletContext();
    const [organizationOptions, setOrganizationOptions] = useState([{
        name: 'Giverist Foundation',
        value: 0
    }]);
	const [formData, setFormData] = useState({
		name: '',
		description: '',
        organizationId: 0,
		startImmediately: false,
		startDate: '',
		startTime: '19:00',
		startTimezone: 'America/New_York',
		startAt: '',
		lengthInDays: '7',
        endDate: '',
		endAt: '',
		goalAmount: '',
		images: []
	});
	const initialErrorObject = {
		name: false,
		description: false,
        organizationId: false,
		startDate: false,
		lengthInDays: false,
        endDate: false,
		goalAmount: false,
		apiError: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		name: 'Name must be at least 10 characters long',
		description: 'Description must be at least 200 characters long',
		startDate: 'Required',
		lengthInDays: 'Required',
        endDate: 'Required',
		goalAmount: 'Required'
	};
	const [creationInProgress, setCreationInProgress] = useState(false);

    const getOrganizations = async () => {
        const response = await getOrganizationsApi();
        let body;
        try{
            body = await response.json();
        }
        catch(e){}
        if(response && response.ok){
            const chargesEnabledOrganizations = [];
            for(let i = 0; i < body.data.organizations.length; i++){
                if(user.organization_id && user.organization_id !== body.data.organizations[i].id){
                    continue;
                }
                if(body.data.organizations[i].charges_enabled){
                    chargesEnabledOrganizations.push({...body.data.organizations[i], value: body.data.organizations[i].id});
                    if(user.organization_id){
                        break;
                    }
                }
            }
            chargesEnabledOrganizations.sort((a, b) => {
                return a.name.localeCompare(b.name)
            });
            setOrganizationOptions([...organizationOptions, ...chargesEnabledOrganizations]);
        }
        else{
            Bugsnag.notify('Get organizations failed');
        }
    };

    const setOrganizations = async () => {
        getOrganizations();
    };

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(key !== 'startAt' && key !== 'endAt'){
                if(key === 'organizationId'){
                    continue;
                }
				if(formData.startImmediately && ['startDate', 'startTime', 'startTimezone', 'endDate'].indexOf(key) === -1 && !formData[key]){
					newErrorObject[key] = inputsMessagesErrorObject[key];
					foundError = true;
				}
				else if(!formData.startImmediately && ['startImmediately', 'endDate'].indexOf(key) === -1 && !formData[key]){
					newErrorObject[key] = inputsMessagesErrorObject[key];
					foundError = true;
				}
                if(formData.lengthInDays === 'custom' && key === 'endDate' && !formData[key]){
                    newErrorObject[key] = inputsMessagesErrorObject[key];
                    foundError = true;
                }
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const checkNameLength = () => {
		if(formData.name.length < 10){
			setErrorObject((prevState) => ({...prevState, name: inputsMessagesErrorObject.name}));
			return false;
		}
		return true;
	};

	const checkDescriptionLength = () => {
		if(formData.description.length < 200){
			setErrorObject((prevState) => ({...prevState, description: inputsMessagesErrorObject.description}));
			return false;
		}
		return true;
	};

    const addDaysToDate = (date, numOfDays) => {
        if(date){
            const newDateObject = new Date(date.getTime());
            return new Date(newDateObject.setDate(newDateObject.getDate() + numOfDays));
        }
        else{
            return null;
        }
    };

    const constructDateObject = (date) => {
        const dateMonth = date.getMonth() + 1;
        const dateDay = date.getDate();
        const dateYear = date.getFullYear();
        return dateYear + '-' + dateMonth + '-' + dateDay;
    };

	const parseDatetime = () => {
		let startAt, endAt;
		if(formData.startImmediately){
			startAt = new Date();
			startAt = startAt.toISOString();
		}
		else{
			if(formData.startDate && formData.startTime && formData.startTimezone){
				const startDate = constructDateObject(formData.startDate);
				startAt = moment.tz(startDate + ' ' + formData.startTime, 'YYYY-M-DD H:mm', formData.startTimezone).utc().format();
			}
			else{
				return;
			}
		}
        if(formData.lengthInDays === 'custom' && formData.endDate){
            const endDate = constructDateObject(formData.endDate);
            if(formData.startImmediately){
                const startAtDateObject = new Date(startAt);
                const endDateDateObject = new Date(endDate);
                const daysDifference = Math.ceil((endDateDateObject - startAtDateObject) / 3.6e6 / 24);
                endAt = moment.tz(startAt).add(daysDifference, 'days').utc().format();
            }
            else{
                endAt = moment.tz(endDate + ' ' + formData.startTime, 'YYYY-M-DD H:mm', formData.startTimezone).utc().format();
            }
        }
        else{
            endAt = moment(startAt).add(Number(formData.lengthInDays), 'days').utc().format();
        }
		setFormData({...formData, startAt: startAt, endAt: endAt});
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setCreationInProgress(true);
		if(checkForEmptyInputs()){
			setCreationInProgress(false);
			return;
		}
		const nameLengthCheck = checkNameLength();
		const descriptionLengthCheck = checkDescriptionLength();
		if(!nameLengthCheck || !descriptionLengthCheck){
			setCreationInProgress(false);
			return;
		}
		const response = await createFundraiserApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			navigate('/fundraiser/' +  body.data.fundraiser_id);
		}
		else{
			const error = body?.error ? body.error : 'Create fundraiser failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setCreationInProgress(false);
	};

	useEffect(() => {
		document.title = 'Create Fundraiser';
	}, []);

    useEffect(() => {
        if(user.id && organizationOptions.length === 1){
            setOrganizations();
        }
    }, [user]);

	useEffect(() => {
		parseDatetime();
	}, [formData.startImmediately, formData.startDate, formData.startTime, formData.startTimezone, formData.lengthInDays, formData.endDate]);

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	return (
		<div>
			<LoggedInNav/>
			<div className={styles.container}>
				<form onSubmit={submit}>
					<div className={styles.topSection}>
						<FormHeader className={styles.header} text="Create fundraiser"/>
						<div className={styles.organizationContainer}>
							<span className={styles.organization}>
								Creating a fundraiser for {organizationOptions[findArrayIndex(organizationOptions, 'value', Number(formData.organizationId))]?.name}
							</span>
						</div>
					</div>
					<div className={styles.middleSection}>
						<div className={styles.leftSide}>
							<FormInput className={styles.singleLineInput} label="Fundraiser name" type="text" maxlength={80} placeholder="ie. HBS Class of 2005 20 Year Reunion"
                                       value={formData.name} update={(event) => setFormData({...formData, name: event.target.value})} error={errorObject.name} required/>
                            <FormDropdown className={styles.singleLineDropdown} label="Organization" value={formData.organizationId} options={organizationOptions}
                                          update={(event) => setFormData({...formData, organizationId: event.target.value})} required/>
							<FormCheckbox className={styles.singleLineCheckbox} label="Start the fundraiser now" value={formData.startImmediately}
										  update={(event) => setFormData({...formData, startImmediately: !!event.target.checked})}/>
							{
								!formData.startImmediately && (
									<div>
										<FormDateInput className={styles.singleLineInput} label="Start date" placeholder="mm/dd/yyyy" value={formData.startDate}
													   update={(value) => setFormData({...formData, startDate: value})} error={errorObject.startDate} required/>
										<div className={styles.startTimeRow}>
											<FormDropdown className={styles.startTime} label="Start time" value={formData.startTime} options={startTimeOptions}
														  update={(event) => setFormData({...formData, startTime: event.target.value})} image={clock} required/>
											<FormDropdown className={styles.startTime} label="Time zone" style={{marginLeft: 'auto'}} value={formData.startTimezone}
                                                          options={startTimezoneOptions} update={(event) => setFormData({...formData, startTimezone: event.target.value})} required/>
										</div>
									</div>
								)
							}
							<FormDropdown className={styles.singleLineDropdown} label="Length" value={formData.lengthInDays} options={lengthsOptions}
										  update={(event) => setFormData({...formData, lengthInDays: event.target.value})} image={refreshClock} error={errorObject.lengthInDays} required/>
                            {
                                formData.lengthInDays === 'custom' && (
                                    <FormDateInput className={styles.singleLineInput} label="End date" placeholder="mm/dd/yyyy" value={formData.endDate}
                                                   update={(value) => setFormData({...formData, endDate: value})}
                                                   minDate={addDaysToDate(formData.startImmediately ? new Date() : formData.startDate, 3)}
                                                   maxDate={addDaysToDate(formData.startImmediately ? new Date() : formData.startDate, 90)}
                                                   error={errorObject.endDate} required/>
                                )
                            }
							<FormMoneyInput className={styles.singleLineMoneyInput} label="Goal" placeholder="$0" value={formData.goalAmount}
											update={(event) => setFormData({...formData, goalAmount: event.target.value})} error={errorObject.goalAmount} required/>
							<ImagesSection formData={formData} setFormData={setFormData} user={user} type="fundraiser"/>
						</div>
						<div className={styles.rightSide}>
							<FormTextEditor className={styles.singleLineTextEdtior} label="Description"
											placeholder="Describe your fundraiser. For example, what would the money be used for, who would benefit, what impact would it have, how would you measure it, and when might you see results?"
											value={formData.description} update={(event) => setFormData({...formData, description: event.target.value})}
											error={errorObject.description} required/>
						</div>
					</div>
					<div className={styles.bottomSection}>
						{
							errorObject.apiError && (
								<FormError className={styles.apiError} error={errorObject.apiError}/>
							)
						}
						<FormButton className={styles.button} text="Create" disabled={creationInProgress}/>
					</div>
				</form>
			</div>
		</div>
	)
};

export default CreateFundraiser;