import close from '../../assets/images/close.png';
import styles from './PhotoModal.module.css';

const PhotoModal = (props) => {
    return (
        <div className={styles.modal}>
            <div className={styles.container}>
                <img className={styles.close} src={close} alt="Close" onClick={props.close}/>
                <img className={styles.image} src={props.url} alt="Image"/>
            </div>
        </div>
    )
};

export default PhotoModal;