import { useState, useEffect } from 'react';
import forgotPassword from '../assets/images/forgot-password.jpg';
import shieldCheckmark from '../assets/images/shield-checkmark.png';
import styles from './ForgotPassword.module.css';
import { forgotPasswordApi } from '../api/auth.js';
import ButtonLink from '../components/ButtonLink.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import FormDescription from '../components/form/FormDescription.jsx';
import FormInput from '../components/form/FormInput.jsx';
import FormButton from '../components/form/FormButton.jsx';
import FormError from '../components/form/FormError.jsx';

const ForgotPassword = () => {
	const [formData, setFormData] = useState({
		email: ''
	});
	const initialErrorObject = {
		email: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		email: 'Missing email'
	};
	const [resetInProgress, setResetInProgress] = useState(false);
	const [resetSuccessful, setResetSuccessful] = useState(false);

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(!formData[key]){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setResetInProgress(true);
		if(checkForEmptyInputs()){
			setResetInProgress(false);
			return;
		}
		const response = await forgotPasswordApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			setResetSuccessful(true);
		}
		else{
			const error = body?.error ? body.error : 'Request failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setResetInProgress(false);
	};

	useEffect(() => {
		document.title = 'Forgot Password';
	}, []);

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				{
					!resetSuccessful && (
						<form className={styles.form} onSubmit={submit}>
							<FormHeader className={styles.header} text="Forgot your password?"/>
							<FormDescription className={styles.description} text="Enter your email address to reset your password"/>
							<FormInput className={styles.singleLineInput} label="Email" type="email" placeholder="ie. johnsmith@gmail.com" value={formData.email}
									   update={(event) => setFormData({...formData, email: event.target.value})} error={errorObject.email} required/>
							{
								errorObject.apiError && (
									<FormError error={errorObject.apiError}/>
								)
							}
							<div className={styles.formBottom}>
								<FormButton text="Send Reset Link" disabled={resetInProgress}/>
							</div>
						</form>
					)
				}
				{
					resetSuccessful && (
						<div>
							<img className={styles.shieldCheckmark} src={shieldCheckmark} alt="Shield Checkmark"/>
							<h2 className={styles.resetSuccessfulHeader}>
								Success!
							</h2>
							<FormDescription text="An email with password reset instructions has been sent to your inbox"/>
						</div>
					)
				}
			</div>
			<div className={styles.banner}>
				<img className={styles.image} src={forgotPassword} alt="Forgot Password"/>
				<div className={styles.bottomBar}>
					<span className={styles.bottomBarText}>
						Forgot your password?
					</span>
					<ButtonLink className={styles.loginButton} href="/login" text="Back to login"/>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;