import { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import moneyCoins from '../assets/images/coins.png'
import styles from './FundraiserCard.module.css';
import getFundraiserStatus from "../utils/getFundraiserStatus";
import formatNumber from "../utils/formatNumber";

const FundraiserCard = (props) => {
	const [status, setStatus] = useState('');
	const [statusClassName, setStatusClassName] = useState('');
	const [progressPercent, setProgressPercent] = useState(0);

	const getStatus = () => {
        const statusObject = getFundraiserStatus(props.fundraiser.start_at, props.fundraiser.end_at)
        setStatus(statusObject.status);
        setStatusClassName(statusObject.statusClassName);
	};

	const getProgressPercent = () => {
		if(!props.fundraiser.total_donations_amount){
			return;
		}
		setProgressPercent(Math.min((props.fundraiser.total_donations_amount / props.fundraiser.goal_amount) * 100, 100));
	};

	useEffect(() => {
		getStatus();
		getProgressPercent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<h3 className={styles.name}>
				{props.fundraiser.name}
			</h3>
			<div className={classNames(styles.status, styles[statusClassName])}>
				{status}
			</div>
			<div className={styles.progress}>
				<span className={styles.label}>
					Progress Towards Goal
				</span>
				<div className={styles.progressBar}>
					<div className={styles.progressMade} style={{width: progressPercent + '%'}}/>
				</div>
			</div>
			<div className={styles.firstInfoRow}>
				<div className={styles.endAt}>
					<span className={styles.label}>
						Deadline
					</span>
					<span className={styles.value}>
						{moment(props.fundraiser.end_at).format('MMM Do, YYYY')}
					</span>
				</div>
				<div className={styles.startAt}>
					<span className={styles.label}>
						Start Date
					</span>
					<span className={styles.value}>
						{moment(props.fundraiser.start_at).format('MMM Do, YYYY')}
					</span>
				</div>
			</div>
			<div className={styles.secondInfoRow}>
				<div className={styles.totalDonations}>
					<span className={styles.label}>
						Amount Donated
					</span>
					<span className={styles.value}>
						{props.fundraiser.total_donations_amount ? '$' + formatNumber(props.fundraiser.total_donations_amount) : 'No Donations'}
					</span>
				</div>
			</div>
			<a className={styles.buttonLink} href={'fundraiser/' + props.fundraiser.id}>
				<img className={styles.moneyCoins} src={moneyCoins} alt="Money Coins"/>
				Manage
			</a>
		</div>
	)
};

export default FundraiserCard;