import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import ReactSlider from 'react-slider';
import { Buffer } from 'buffer';
import close from '../../assets/images/close.png';
import styles from './PictureModal.module.css';
import useS3 from '../../hooks/useS3.js';
import getEditedImage from '../../utils/getEditedImage.js';
import Button from '../Button.jsx';

const PictureModal = (props) => {
	const s3 = useS3();
	const [crop, setCrop] = useState({
		x: 0,
		y: 0
	});
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [zoom, setZoom] = useState(1);
	const [uploadInProgress, setUploadInProgress] = useState(false)

	const onCropComplete = useCallback((croppedArea, croppedPixels) => {
		setCroppedAreaPixels(croppedPixels);
	}, []);

	const uploadImage = async () => {
		setUploadInProgress(true);
		const editedImageObject = await getEditedImage(props.imageBase64, croppedAreaPixels);
        let folder;
        if(props.type === 'personal'){
            folder = 'users-images';
        }
        else if(props.type === 'organization'){
            folder = 'organizations-images';
        }
        else if(props.type === 'fundraiser'){
            folder = 'fundraisers-images';
        }
        else if(props.type === 'fundraiser-update'){
            folder = 'fundraiser-updates-images';
        }
		const key = folder + '/' + props.user.id + '-' + Date.now() + '-' + Math.round(Math.random() * (99999999 - 10000000) + 10000000) + '.jpg';
		try{
			const editedImageBuffer = Buffer.from(editedImageObject.base64Data.replace(/^data:image\/\w+;base64,/, ''),'base64')
			await s3.putObject({
				Bucket: process.env.REACT_APP_S3_BUCKET,
				Key: key,
				ContentEncoding: 'base64',
				ContentType: 'image/jpeg',
				ACL: 'public-read',
				Body: editedImageBuffer
			});
			const url = '/' + key;
			props.update(url);
			props.close();
		}
		catch(e){
			alert('Something went wrong. Image could not be uploaded');
			setUploadInProgress(true);
		}
	};

	return (
		<div className={styles.modal}>
			<div className={styles.container}>
				<div className={styles.header}>
					Edit and upload image
				</div>
				<img className={styles.close} src={close} alt="Close" onClick={props.close}/>
				<div className={styles.cropperContainer}>
					<Cropper image={props.imageBase64} crop={crop} cropShape={['fundraiser', 'fundraiser-update'].indexOf(props.type) > - 1 ? 'rect' : 'round'} zoom={zoom}
                             aspect={['fundraiser', 'fundraiser-update'].indexOf(props.type) > - 1 ? 16 / 9 : 1} minZoom={1} maxZoom={2} showGrid={false} restrictPosition={true}
                             onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom}/>
				</div>
				<ReactSlider className={styles.slider} value={zoom} min={1} max={2} step={0.05} onChange={(newValue) => setZoom(newValue)}
							 renderTrack={(properties, state) => (
								 <div className={state.index === 1 ? styles.sliderTrack : ''}/>
							 )}
							 renderThumb={(properties) => {
								 properties = {...properties, className: styles.sliderThumbContainer}
								 return <div {...properties}><div className={styles.sliderThumb}/></div>
							 }}/>
				<div className={styles.buttons}>
					<Button className={styles.button} text="Cancel" onClick={props.close}/>
					<Button className={styles.button} style={{marginLeft: 'auto'}} text="Upload" dark={true} disabled={uploadInProgress} onClick={uploadImage}/>
				</div>
			</div>
		</div>
	)
};

export default PictureModal;