const getOrganizationsApi = async () => {
    const token = localStorage.getItem('api-token');
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: token
        }
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/organizations', options);
};

const getOrganizationApi = async (organizationId) => {
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/organization/' + organizationId, options);
};

const getOrganizationStripeLinkApi = async() => {
    const token = localStorage.getItem('api-token');
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: token
        }
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/stripe/organization', options);
};

const updateOrganizationApi = async(formData) => {
    const token = localStorage.getItem('api-token');
    const editedFormData = {
        name: formData.name,
        street_one: formData.streetOne,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        ein: formData.ein,
        picture_url: formData.pictureUrl
    };
    const options = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: token
        },
        body: JSON.stringify(editedFormData)
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/organization', options);
};

const updateOrganizationSlugApi = async(slug) => {
    const token = localStorage.getItem('api-token');
    const slugObject = {
        slug: slug
    };
    const options = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: token
        },
        body: JSON.stringify(slugObject)
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/organization/slug', options);
};

export { getOrganizationsApi, getOrganizationApi, getOrganizationStripeLinkApi, updateOrganizationApi, updateOrganizationSlugApi };