import { useState, useEffect } from "react";
import pencil from '../../assets/images/pencil.png';
import close from '../../assets/images/close.png';
import floppyDisc from '../../assets/images/floppy-disc.png';
import checkmarkGray from '../../assets/images/checkmark-gray.png';
import copy from "../../assets/images/copy.png";
import styles from './OrganizationUrl.module.css';
import { updateOrganizationSlugApi } from '../../api/organizations';
import FormError from "../form/FormError";

const OrganizationUrl = (props) => {
    const [organizationId, setOrganizationId] = useState(props.organizationId);
    const [organizationSlug, setOrganizationSlug] = useState(props.organizationSlug);
    const [showUrlEdit, setShowUrlEdit] = useState(false);
    const [slug, setSlug] = useState('');
    const [error, setError] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [copyStatus, setCopyStatus] = useState(false);

    const copyUrl = () => {
        const slug = organizationSlug ? organizationSlug : organizationId;
        navigator.clipboard.writeText(process.env.REACT_APP_FRONT_END_DOMAIN + '/organization/' + slug);
        setCopyStatus('Copied!')
    };

    const editSlug = () => {
        setCopyStatus(false);
        setError(false);
        setShowUrlEdit(true);
    };

    const saveSlug = async (event) => {
        event.preventDefault();
        setError(false);
        if(!slug){
            setError('You must provide a slug');
            return;
        }
        const response = await updateOrganizationSlugApi(slug);
        let body;
        try{
            body = await response.json();
        }
        catch(e){}
        if(response && response.ok){
            setOrganizationSlug(slug);
            setShowUrlEdit(false);
            setSaveSuccessful(true);
            setTimeout(() => {
                setSaveSuccessful(false);
            }, 3000);
        }
        else{
            const errorText = body?.error ? body.error : 'Update failed';
            setError(errorText);
        }
    };

    useEffect(() => {
        const allowedValuesRgex = new RegExp("^[A-Za-z0-9-]+$");
        if(slug && !allowedValuesRgex.test(slug)){
            setError('Slug can only contain letters, numbers, and dashes');
        }
        else if(slug && !isNaN(slug)){
            setError('Slug can not only contain numbers');
        }
        else{
            setError(false);
        }
    }, [slug]);

    return (
        <div className={props.className}>
            <label className={styles.label}>
                Link
            </label>
            <div className={styles.urlRow}>
                {
                    !organizationSlug && !showUrlEdit && (
                        <img className={styles.pencil} src={pencil} alt="Pencil" title="Add custom URL" onClick={editSlug}/>
                    )
                }
                {
                    showUrlEdit && (
                        <img className={styles.cancel} src={close} alt="Cancel" title="Cancel" onClick={() => setShowUrlEdit(false)}/>
                    )
                }
                <span className={styles.url}>
                    {
                        <span className={showUrlEdit ? styles.urlPrefixFixedWidth : ''}>
                            {process.env.REACT_APP_FRONT_END_DOMAIN}/organization{!showUrlEdit ? '/' : ''}
                        </span>
                    }
                    {
                        !showUrlEdit && (
                            <span>
                                {organizationSlug ? organizationSlug : organizationId}
                            </span>
                        )
                    }
                    {
                        showUrlEdit && (
                            <span className={styles.slugContainer}>
                                {
                                    showUrlEdit ? '/' : ''
                                }
                                <input className={styles.slug} type="text" value={slug} maxLength={50} onChange={(event) => setSlug(event.target.value)}/>
                            </span>
                        )
                    }
                </span>
                {
                !error && showUrlEdit && (
                        <img className={styles.floppyDisk} src={floppyDisc} alt="Save" title="Save custom URL" onClick={saveSlug}/>
                    )
                }
                {
                    saveSuccessful && !showUrlEdit && (
                        <span className={styles.saveStatus}>
                            <img className={styles.saveStatusIcon} src={checkmarkGray} alt="Checkmark"/>
                            <span className={styles.saveStatusText} onClick={copyUrl}>
                                Saved
                            </span>
                        </span>

                    )
            }
            {
                copyStatus === false && !saveSuccessful && !showUrlEdit && (
                    <img className={styles.copy} src={copy} alt="Copy" title="Copy link" onClick={copyUrl}/>
                    )
                }
                {
                    copyStatus !== false && !saveSuccessful && !showUrlEdit && (
                        <span className={styles.copyStatus}>
                            <img className={styles.copyStatusIcon} src={checkmarkGray} alt="Checkmark"/>
                            <span className={styles.copyStatusText} onClick={copyUrl}>
                                {copyStatus}
                            </span>
                        </span>
                    )
                }
            </div>
            {
                error && showUrlEdit && (
                    <FormError error={error}/>
                )
            }
        </div>
    );
};

export default OrganizationUrl;