import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import badgeBlack from '../assets/images/badge-black.png';
import badgeTeal from '../assets/images/badge-teal.png';
import buildingBlack from '../assets/images/building-black.png';
import buildingTeal from '../assets/images/building-teal.png';
import shieldBlack from '../assets/images/shield-black.png';
import shieldTeal from '../assets/images/shield-teal.png';
import styles from './Profile.module.css';
import { getUserApi } from '../api/users.js';
import LoggedInNav from '../components/logged-in/LoggedInNav.jsx';
import MenuDropdown from '../components/profile/MenuDropdown.jsx';
import Personal from '../components/profile/Personal.jsx';
import Organization from '../components/profile/Organization.jsx';
import Security from '../components/profile/Security.jsx';

const Profile = () => {
	const { view } = useParams();
	const navigate = useNavigate();
	const [selectedView, setSelectedView] = useState(view || 'personal');
	const [user, setUser] = useState({});

	const getUser = async () => {
		const response = await getUserApi();
		const body = await response.json();
		if(response && response.ok){
			setUser(body.data.user);
		}
	};

	const updateSelectedView = (view) => {
		setSelectedView(view);
		navigate('/profile/' + view);
	};

	useEffect(() => {
		getUser();
		document.title = 'Profile';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<LoggedInNav/>
			<div className={styles.container}>
				<div className={styles.menu}>
					<button className={classNames(styles.menuLink, {[styles.selected]: selectedView === 'personal'})} onClick={() => updateSelectedView('personal')}>
						{
							selectedView === 'personal' ? (
								<img className={styles.menuLinkIcon} src={badgeTeal} alt="Personal info"/>
							) : (
								<img className={styles.menuLinkIcon} src={badgeBlack} alt="Personal info"/>
							)
						}
						Personal info
					</button>
                    {
                        user.organization_id && (
                            <button
                                className={classNames(styles.menuLink, {[styles.selected]: selectedView === 'organization'})} onClick={() => updateSelectedView('organization')}>
                                {
                                    selectedView === 'organization' ? (
                                        <img className={styles.menuLinkIcon} src={buildingTeal} alt="Organization info"/>
                                    ) : (
                                        <img className={styles.menuLinkIcon} src={buildingBlack} alt="Organization info"/>
                                    )
                                }
                                Organization info
                            </button>
                        )
                    }
                    <button className={classNames(styles.menuLink, {[styles.selected]: selectedView === 'security'})}
                            onClick={() => updateSelectedView('security')}>
                        {
                            selectedView === 'security' ? (
                                <img className={styles.menuLinkIcon} src={shieldTeal} alt="Security"/>
                            ) : (
                                <img className={styles.menuLinkIcon} src={shieldBlack} alt="Security"/>
                            )
                        }
                        Security
                    </button>
                </div>
                <div className={styles.views}>
					<MenuDropdown className={styles.menuDropdown} view={selectedView} update={updateSelectedView}/>
					{
						selectedView  === 'personal' && (
							<Personal className={styles.personalInfo} user={user}/>
						)
					}
					{
						selectedView  === 'organization' && user.organization_id && (
							<Organization className={styles.organizationInfo} user={user} getUser={getUser}/>
						)
					}
					{
						selectedView  === 'security' && (
							<Security className={styles.security}/>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default Profile;