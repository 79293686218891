import { useState, useEffect } from 'react';
import { getAllOrgFundraisersApi } from '../api/fundraisers.js';
import hand from '../assets/images/hand.png';
import people from '../assets/images/people.png';
import magnifyingGlassWithQuestionMark from '../assets/images/magnifying-glass-with-quesiton-mark.png';
import coinJar from '../assets/images/coin-jar.png';
import styles from './Dashboard.module.css';
import formatNumber from '../utils/formatNumber.js';
import LoggedInNav from '../components/logged-in/LoggedInNav.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import SearchInput from '../components/SearchInput.jsx';
import SearchOrder from '../components/SearchOrder.jsx';
import FundraiserCard from '../components/FundraiserCard.jsx';
import ButtonLink from '../components/ButtonLink.jsx';

const Dashboard = () => {
	const [fundraisers, setFundraisers] = useState([]);
    const [filteredFundraisers, setFilteredFundraisers] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortBy, setSortBy] = useState('newest');
	const [sortByLabel, setSortByLabel] = useState('Newest');
	const [totalNumOfDonations, setTotalNumOfDonations] = useState(0);
	const [totalDonationsAmount, setTotalDonationsAmount] = useState('');
	const filterOptions = [{
		label: 'Newest',
		value: 'newest'
	}, {
		label: 'Oldest',
		value: 'oldest'
	}, {
		label: 'Most Raised',
		value: 'amount'
	}];

	const getFundraisers = async() => {
		const response = await getAllOrgFundraisersApi();
		const body = await response.json();
		if(response && response.ok){
			setFundraisers(body.data.fundraisers);
		}
	};

	const updateSort = (label, value) => {
		setSortByLabel(label);
		setSortBy(value);
	};

	const sortByOrder = (a, b) => {
		if(sortBy === 'newest'){
			if(a.end_at > b.end_at){
				return -1;
			}
			else if(a.end_at < b.end_at){
				return 1;
			}
			return 0;
		}
		else if(sortBy === 'oldest'){
			if(a.end_at < b.end_at){
				return -1;
			}
			else if(a.end_at > b.end_at){
				return 1;
			}
			return 0;
		}
		else if(sortBy === 'amount'){
			return b.total_donations_amount - a.total_donations_amount;
		}
	};

	const calculateImpact = () => {
		let numOfDonations = 0;
		let donationsAmount = 0;
		for(let i = 0; i < fundraisers.length; i++){
			numOfDonations = numOfDonations + Number(fundraisers[i].total_num_of_donations);
			if(fundraisers[i].total_donations_amount){
				donationsAmount = donationsAmount + Number(fundraisers[i].total_donations_amount);
			}
		}
		if(donationsAmount >= 1000){
			donationsAmount = Math.floor(donationsAmount / 1000).toString() + 'K';
		}
		else{
			donationsAmount = Math.floor(donationsAmount);
		}
		setTotalNumOfDonations(numOfDonations);
		setTotalDonationsAmount(donationsAmount);
	};

	useEffect(() => {
		getFundraisers();
		document.title = 'Dashboard';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    useEffect(() => {
        setFilteredFundraisers(fundraisers.filter(((fundraiser) => {
            if(searchTerm){
                return fundraiser.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            }
            else{
                return true;
            }
        })));
    }, [fundraisers, searchTerm]);

	useEffect(() => {
		calculateImpact();
	}, [fundraisers]);

	return (
		<div>
			<LoggedInNav/>
			<div className={styles.container}>
				<div className={styles.fundraisers}>
					<div className={styles.topSection}>
						<FormHeader className={styles.fundraisersHeader} text="Fundraisers"/>
						<div className={styles.rightSide}>
							<SearchInput className={styles.searchInput} placeholder="Search" value={searchTerm} update={(event) => setSearchTerm(event.target.value)}/>
							<SearchOrder className={styles.searchOrder} label={sortByLabel} options={filterOptions} value={sortBy} update={updateSort}/>
						</div>
					</div>
					<div className={styles.bottomSection}>
						{
                            filteredFundraisers.length > 0 && filteredFundraisers.sort(sortByOrder).map(fundraiser => (
								<FundraiserCard className={styles.fundraiser} fundraiser={fundraiser} key={'fundraiser-' + fundraiser.id}/>
							))
						}
                        {
                            filteredFundraisers.length === 0 && fundraisers.length > 0 && (
                                <div className={styles.noFundraisersFound}>
                                    <img className={styles.magnifyingGlassWithQuestionMark} src={magnifyingGlassWithQuestionMark} alt="Coin Jar"/>
                                    <div className={styles.noFundraisersDescription}>
                                        No fundraisers matching your search
                                    </div>
                                    <div className={styles.noFundraisersFooter}>
                                        0 search results for
                                        <span className={styles.noFundraisersFooterBold}>
                                            “{searchTerm}"
                                        </span>
                                    </div>
                                </div>
                            )
                        }
						{
							fundraisers.length === 0 && (
								<div className={styles.noFundraisers}>
									<img className={styles.coinJar} src={coinJar} alt="Coin Jar"/>
									<div className={styles.noFundraisersDescription}>
										You have not started a fundraiser
									</div>
									<ButtonLink className={styles.noFundraisersButton} href="/fundraiser" text="Start a fundraiser"/>
								</div>
							)
						}
					</div>
				</div>
				<div className={styles.impact}>
					<h2 className={styles.impactHeader}>
						Impact
					</h2>
					<div className={styles.impactStats}>
						<div className={styles.totalDonationsAmount}>
							<div className={styles.totalDonationsAmountDisplay}>
								<img className={styles.impactImage} src={hand} alt="Hand"/>
							</div>
							<div className={styles.impactDetails}>
							<span className={styles.impactLabel}>
								Total Raised
							</span>
								<span className={styles.impactValue}>
								${totalDonationsAmount}
							</span>
							</div>
						</div>
						<div className={styles.totalNumOfDonations}>
							<div className={styles.totalNumOfDonationsDisplay}>
								<img className={styles.impactImage} src={people} alt="People"/>
							</div>
							<div className={styles.impactDetails}>
							<span className={styles.impactLabel}>
								Total Donations
							</span>
								<span className={styles.impactValue}>
								{formatNumber(totalNumOfDonations)}
							</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default Dashboard;