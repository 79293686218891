import { useState, useEffect } from 'react';
import {useNavigate, useLocation, useOutletContext, useParams} from "react-router-dom";
import magnifyingGlassWithQuestionMark from '../assets/images/magnifying-glass-with-quesiton-mark.png';
import styles from './Organization.module.css';
import { getOrganizationApi } from "../api/organizations";
import LoggedInNav from "../components/logged-in/LoggedInNav";
import LoggedOutNav from '../components/logged-out/LoggedOutNav.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import SearchInput from '../components/SearchInput.jsx';
import SearchOrder from '../components/SearchOrder.jsx';
import FundraiserListing from '../components/FundraiserListing.jsx';

const Organization = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { organizationId } = useParams();
    const { user } = useOutletContext();
    const [organization, setOrganization] = useState({});
    const [fundraisers, setFundraisers] = useState([]);
    const [filteredFundraisers, setFilteredFundraisers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('newest');
    const [sortByLabel, setSortByLabel] = useState('Newest');
    const sortOptions = [{
        label: 'Newest',
        value: 'newest'
    }, {
        label: 'Oldest',
        value: 'oldest'
    }, {
        label: 'Most Raised',
        value: 'amount'
    }];

    const getOrganization = async() => {
        const response = await getOrganizationApi(organizationId);
        let body;
        try{
            body = await response.json();
        }
        catch(e){}
        if(response && response.ok){
            setOrganization(body.data.organization);
            setFundraisers(body.data.in_progress_fundraisers);
            document.title = body.data.organization?.name;
        }
        else{
            navigate('/404');
        }
    };

    const updateSort = (label, value) => {
        setSortByLabel(label);
        setSortBy(value);
    };

    const sortByOrder = (a, b) => {
        if(sortBy === 'newest'){
            if(a.end_at > b.end_at){
                return -1;
            }
            else if(a.end_at < b.end_at){
                return 1;
            }
            return 0;
        }
        else if(sortBy === 'oldest'){
            if(a.end_at < b.end_at){
                return -1;
            }
            else if(a.end_at > b.end_at){
                return 1;
            }
            return 0;
        }
        else if(sortBy === 'amount'){
            return b.total_donations_amount - a.total_donations_amount;
        }
    };

    useEffect(() => {
        getOrganization();
        document.title = 'Giverist';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilteredFundraisers(fundraisers.filter(((fundraiser) => {
            if(searchTerm){
                return fundraiser.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1  ||
                    fundraiser.organization_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
            }
            else{
                return true;
            }
        })));
    }, [fundraisers, searchTerm]);

    return (
        <div>
            {
                user.id ? (
                    <LoggedInNav/>
                ) : (
                    <LoggedOutNav from={location.state?.from}/>
                )
            }
            <div className={styles.container}>
                <div className={styles.fundraisers}>
                    <div className={styles.topSection}>
                        {
                            <div className={styles.organizationContainer}>
                                {
                                    organization.picture_url && (
                                        <img className={styles.organizationPicture} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + organization.picture_url}
                                             alt="Organization"/>
                                    )
                                }
                                <FormHeader className={styles.organizationName} text={organization.name}/>
                            </div>
                        }
                        <div className={styles.rightSide}>
                            <SearchInput className={styles.searchInput} placeholder="Search" value={searchTerm} update={(event) => setSearchTerm(event.target.value)}/>
                            <SearchOrder className={styles.searchOrder} label={sortByLabel} options={sortOptions} value={sortBy} update={updateSort}/>
                        </div>
                    </div>
                    <div className={styles.bottomSection}>
                        {
                            filteredFundraisers.length > 0 && filteredFundraisers.sort(sortByOrder).map(fundraiser => (
                                <FundraiserListing className={styles.fundraiser} fundraiser={fundraiser} hideOrganization={true} key={'fundraiser-' + fundraiser.id}/>
                            ))
                        }
                        {
                            filteredFundraisers.length === 0 && (
                                <div className={styles.noFundraisers}>
                                    <img className={styles.magnifyingGlassWithQuestionMark} src={magnifyingGlassWithQuestionMark} alt="Magnifying Glass With Question Mark"/>
                                    <div className={styles.noFundraisersDescription}>
                                        No fundraisers found
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Organization;